import CompanyApiCommon from '@jetCommon/api/company.js';

export default class CompanyApi extends CompanyApiCommon {
    static resourceName = 'companies';

    getLastApprovedPayroll(companyId) {
        return this.apiGet(`${companyId}/last_approved_payroll/`);
    }

    getOnboarding(companyId, params = {}) {
        return this.apiGet(`${companyId}/onboarding/`, {params});
    }

    sendInvitations(companyId) {
        return this.apiPost(`${companyId}/send_invitations/`);
    }

    getLastPaymentStatus(companyId) {
        return this.apiGet(`${companyId}/last_payment_status/`);
    }

    assignAccountingOffice(companyId, data) {
        return this.patch(`${companyId}/assign_accounting_office`, data);
    }
}
