<script setup>
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const props = defineProps({
        actionText: {
            type: String,
            default: 'Compila',
        },
        codename: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
    });

    const {taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[props.codename]);

    const actions = computed(() => {
        const visitExternalUrl = {
            main: true,
            text: props.actionText,
            callback() {
                window.open(task.value.setup_data?.url, '_blank');
            },
        };

        return task.value.status === 'TODO' && task.value.setup_data?.url ? [visitExternalUrl] : [];
    });
</script>

<template>
    <OnboardingTaskItem :actions="actions" :codename="codename" :description="description" :title="title" />
</template>
