<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import JetTaskItem from '@jetDS/components/JetTaskItem.vue';

    const {taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.IBAN]);

    const metaText = computed(() => {
        return (
            task.value?.submitted_data?.iban ||
            (task.value?.status === ONBOARDING_TASK_STATUS.DONE ? '' : 'Disponibile a breve')
        );
    });
    const status = computed(() => {
        return task.value?.status === ONBOARDING_TASK_STATUS.DONE || task.value?.submitted_data?.iban
            ? 'DONE'
            : 'PROGRESS';
    });
</script>

<template>
    <JetTaskItem :meta-text="metaText" :status="status" title="IBAN per i pagamenti" />
</template>
