import {ApiBase} from '@jetCommon/api/base.js';

export default class AciCarCostApiCommon extends ApiBase {
    static resourceName = 'aci_car_costs';

    brands(params) {
        return this.apiGet('brands/', {params});
    }

    modelSeries(params) {
        if (!params.brand) {
            return Promise.resolve([]);
        }
        return this.apiGet('', {params});
    }
}
