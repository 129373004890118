import SetupApiCommon from '@jetCommon/api/setup.js';

export default class SetupApi extends SetupApiCommon {
    initCompanyPaymentSetup(specialLinkCode) {
        return this.apiPost(
            'init_company_payment_setup/',
            {},
            {
                headers: {
                    ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
                },
            },
        );
    }

    completeCompanyPaymentSetup(specialLinkCode, data) {
        return this.apiPost('complete_company_payment_setup/', data, {
            headers: {
                ...this.getSpecialLinkCodeRequestHeader(specialLinkCode),
            },
        });
    }
}
