<script setup>
    import {pascalCase} from '@jetCommon/helpers/text.js';
    import {useDesignSystemTools} from '@jetCommon/composables/design-system-tools';

    import JetButton from './JetButton.vue';
    import JetDialog from './JetDialog.vue';
    import JetTaskIcon from './JetTaskIcon.vue';

    const props = defineProps({
        actions: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: null,
        },
        metaText: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: 'TODO',
            validator: value => ['TODO', 'PROGRESS', 'DONE'].includes(value),
        },
        title: {
            type: String,
            required: true,
        },
    });

    const emit = defineEmits(['action', 'click', 'dialog-confirm']);
    const {isMdAndUp} = useDesignSystemTools();

    const dialogs = computed(() => {
        return props.actions.filter(action => action.dialog).map(action => action.dialog);
    });
    const showDialog = reactive({});
    watch(
        dialogs,
        () => {
            dialogs.value.forEach(dialog => {
                showDialog[dialog.name] = false;
            });
        },
        {immediate: true},
    );

    function onAction(action) {
        if (!action || action.disabled) {
            return;
        }
        if (action.dialog) {
            showDialog[action.dialog.name] = !showDialog[action.dialog.name];
        }
        if (action.eventName) {
            emit('action', action.eventName);
        }
        if (action.callback) {
            action.callback();
        }
    }

    function onDialogConfirm(dialog) {
        if (dialog.confirm_callback) {
            dialog.confirm_callback();
        }

        emit('dialog-confirm', dialog);
    }

    defineExpose({
        onAction,
    });
</script>

<template>
    <div class="JetTaskItem py-6 pr-6 pl-10" :class="{_done: status === 'DONE'}" @click="$emit('click', $event)">
        <div class="JetTaskItem_Check mr-3">
            <JetTaskIcon :status="status" />
        </div>
        <div class="JetTaskItem_Content mr-auto">
            <div class="JetTaskItem_MainContent">
                <h2 class="JetTaskItem_Title">
                    {{ title }}
                </h2>
                <h3 v-if="description" class="JetTaskItem_Description mt-1">
                    {{ description }}
                </h3>
            </div>
            <div class="JetTaskItem_MetaContent mt-3" :class="isMdAndUp ? 'ml-auto mt-0' : 'mt-3'">
                <JetButton
                    v-for="(action, index) in actions"
                    :key="index"
                    class="JetTaskItem_ActionButton"
                    :disabled="action.disabled"
                    @click.stop="onAction(action)">
                    {{ action.text }}
                </JetButton>
                <p v-if="metaText" class="JetTaskItem_MetaText">
                    {{ metaText }}
                </p>
            </div>
        </div>
        <JetDialog
            v-for="dialog in dialogs"
            :key="dialog.name"
            v-model:show="showDialog[dialog.name]"
            append-to-body
            destroy-on-close
            :title="dialog.title">
            <template #body>
                <slot :name="`dialog${pascalCase(dialog.name)}Body`" />
            </template>
            <template v-if="dialog?.show_confirm_button ?? true" #footer>
                <slot :name="`dialog${pascalCase(dialog.name)}Footer`">
                    <JetButton type="primary" @click="onDialogConfirm(dialog)">
                        {{ dialog.confirm_button_text || 'Conferma' }}
                    </JetButton>
                </slot>
            </template>
        </JetDialog>
    </div>
</template>

<style scoped lang="scss">
    @use '@jetDS/scss/_media-queries.scss' as *;

    .JetTaskItem {
        cursor: pointer;
        display: flex;
        @include media-query('md-and-up') {
            align-items: center;
        }

        &:hover {
            background-color: #f8faf5;
        }

        &_Check {
            flex: 0 0 24px;
        }

        &_Content {
            display: flex;
            flex-direction: column;
            flex: 1;

            @include media-query('md-and-up') {
                align-items: center;
                flex-flow: row nowrap;
            }
        }

        &_MainContent {
            align-items: center;
            flex: 1;
            @include media-query('md-and-up') {
                flex: 1 1 auto;
            }
        }

        &_MetaContent {
            align-items: center;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            gap: 16px;
            @include media-query('md-and-up') {
                flex: 0 0 auto;
                justify-content: flex-end;
                min-width: 33%;
            }
        }

        &_Title {
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-semibold);
            line-height: var(--jet-line-height-xs);
        }

        &_Description {
            color: var(--jet-text-color-secondary);
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-regular-medium);
            line-height: var(--jet-line-height-xs);
            letter-spacing: -0.01px;
        }

        // Disable JetButton default margin
        &_ActionButton {
            & + & {
                margin-left: var(--jet-sp-0);
            }
        }

        &_MetaText {
            color: var(--jet-text-color-secondary);
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-regular-medium);
            line-height: var(--jet-line-height-xs);
            letter-spacing: -0.01px;
            @include media-query('md-and-up') {
                order: -1;
                white-space: nowrap;
            }
        }

        &_ActionButton + &_MetaText {
            margin-left: var(--jet-sp-2);
            @include media-query('md-and-up') {
                margin-left: var(--jet-sp-0);
                margin-right: var(--jet-sp-2);
            }
        }

        &._done {
            .JetTaskItem_Description,
            .JetTaskItem_Title {
                opacity: 0.5;
            }
        }
    }
</style>
