/*
 * This JS module can be used to configure a new type of policy.
 * Once these constants are defined, the custom settings module
 * must be defined in company-policy/{policy-name}/PolicySettings{PolicyName}.vue
 * and added injected in company-policy/views/CompanyPolicyView.vue
 */
import {CONTRACT_STATUS} from '@jetCommon/constants/employee-constants.js';

import ClockingPolicySettings from './clocking/components/ClockingPolicySettings.vue';
import PolicySettingsExpense from '@/features/company-policy/expense/components/PolicySettingsExpense.vue';

export const COMPANY_POLICY_CATEGORY = {
    EXPENSE: 'EXPENSE',
    CLOCKING: 'CLOCKING',
};

export const COMPANY_POLICY_CONFIGS = {
    [COMPANY_POLICY_CATEGORY.EXPENSE]: {
        routerKey: 'rimborsi-spese',
        companySettingsIsEnabledField: 'expenses_enabled',
        title: 'Rimborsi spese',
        groupLabel: 'Policy rimborsi spese',
        sectionRoute: 'expensesView',
        policyAPI: 'companyExpensePolicy',
        canAdminSetEnabledStatus: true,
        // This component accepts a prop `policy` which is the policy object
        // and emits an event @updated-form which should be triggered when the
        // policy form is updated.
        settingsComponent: PolicySettingsExpense,
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
        },
    },
    [COMPANY_POLICY_CATEGORY.CLOCKING]: {
        routerKey: 'timbrature',
        companySettingsIsEnabledField: 'clockings_enabled',
        title: 'Timbrature',
        groupLabel: 'Policy timbrature',
        // TODO: to be defined once we develop the feature
        sectionRoute: '',
        policyAPI: 'companyClockingPolicy',
        canAdminSetEnabledStatus: false,
        settingsComponent: ClockingPolicySettings,
        assigneeTableInfoCalloutContent:
            'Puoi assegnare una policy di timbratura solamente alle persone con orario fisso.',
        employeeQueryParams: {
            contract_status__in: `${CONTRACT_STATUS.IN_FORCE},${CONTRACT_STATUS.INCOMING}`,
            working_hours_kind: 'FIXED',
        },
    },
};

export const ROUTER_KEYS = Object.keys(COMPANY_POLICY_CONFIGS).map(key => COMPANY_POLICY_CONFIGS[key].routerKey);
