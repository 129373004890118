import {ApiBase} from '@jetCommon/api/base.js';

export default class AgendaItemsApiCommon extends ApiBase {
    static resourceName = 'agenda_items';

    getItems(params) {
        return this.apiGet('search/', {params});
    }

    getFeedICSUrl(params) {
        return this.apiGet('feed_ics_url/', {params});
    }
}
