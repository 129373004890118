<script setup>
    import {useClipboard} from '@vueuse/core';

    import JetButton from '@jetDS/components/JetButton.vue';

    const props = defineProps({
        actionCopyDescription: {
            type: [Boolean, String],
            default: false,
        },
        actionIcon: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
    });
    const emit = defineEmits(['action']);
    const {copy} = useClipboard();
    const {$jetMessage} = inject('jet');

    const internalActionIcon = computed(() => {
        return props.actionCopyDescription ? 'copy' : props.actionIcon;
    });

    function doAction() {
        if (props.actionCopyDescription) {
            copy(typeof props.actionCopyDescription === 'string' ? props.actionCopyDescription : props.description);
            $jetMessage.success('Copiato');
        } else {
            emit('action');
        }
    }
</script>

<template>
    <div class="OnboardingInfoBox p-4">
        <div class="OnboardingInfoBox_Texts">
            <p class="bold">
                {{ title }}
            </p>
            <p class="OnboardingInfoBox_Description">
                <slot name="description">
                    {{ description }}
                </slot>
            </p>
        </div>
        <div class="OnboardingInfoBox_Right ml-4">
            <JetButton icon-style="regular" :icon-name="internalActionIcon" @click="doAction" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .OnboardingInfoBox {
        align-items: center;
        display: flex;
        position: relative;

        & + &::before {
            content: '';
            display: block;
            width: calc(100% - 32px);
            height: 0;
            left: 16px;
            top: 0;
            border-top: 1px solid #d9d9d9;
            position: absolute;
        }

        &_Texts {
            flex: 1 1 auto;
            font-size: var(--jet-font-size-base);
        }

        &_Right {
            flex: 0 0 auto;
        }

        &_Description {
            font-size: 1rem;
            color: #4a5568;
        }
    }
</style>
