import {ApiBase} from '@jetCommon/api/base.js';

export default class AbsenceRequestApiCommon extends ApiBase {
    static resourceName = 'absence_requests';

    async requestDateRules(params) {
        return await this.apiGet('request_date_rules/', {params});
    }

    async kindChoices(params) {
        return await this.apiGet('kind_choices/', {params});
    }

    async whoIsOutKindChoices(params) {
        return await this.apiGet('who_is_out_kind_choices/', {params});
    }

    async periodChoices(params) {
        return await this.apiGet('period_choices/', {params});
    }

    approve(id) {
        return this.apiPost(`${id}/approve/`);
    }

    reject(data) {
        return this.apiPost('reject/', data);
    }

    checkEmployeesConflicts(data) {
        return this.apiPost('check_employees_conflicts/', data);
    }

    duplicateLastUsedAttachment(data) {
        return this.apiPost('duplicate_last_used_attachment/', data);
    }

    bulkApprove(absenceRequestsIds) {
        return this.apiPost('bulk_approve/', absenceRequestsIds);
    }

    hourlyAbsenceStartTimes(employee, day, kind) {
        return this.apiGet('hourly_absence_start_times/', {params: {employee, day, kind}});
    }

    hourlyAbsenceDurations(employee, day, start_time, kind) {
        return this.apiGet('hourly_absence_durations/', {params: {employee, day, start_time, kind}});
    }
}
