<script setup>
    import 'dayjs/locale/it.js';
    import 'element-plus/es/components/date-picker/style/index';
    import {ElDatePicker} from 'element-plus';
    import {format as formatDate} from 'date-fns';

    const props = defineProps({
        format: {
            type: String,
            default: 'DD/MM/YYYY',
        },
        // String with format YYYY-MM-DD, example: '2023-04-26'
        modelValue: {
            type: [String, null],
            required: true,
        },
        minValidDate: {
            type: String,
            required: false,
            default: '',
        },
        maxValidDate: {
            type: String,
            required: false,
            default: '',
        },
        fullWidth: {type: Boolean, default: false},
        enableYearArrowNavigation: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    const isMobile = computed(() => {
        const UA = navigator.userAgent;

        const uaIsMobile = navigator.userAgent.includes('Mobi');
        const isWebkitiOS =
            /\b(iPad|iPhone|iPod)\b/.test(UA) && /WebKit/.test(UA) && !/Edge/.test(UA) && !window.MSStream;

        return isWebkitiOS || uaIsMobile;
    });

    const internalModelValue = ref(props.modelValue);
    watch(
        () => props.modelValue,
        () => {
            if (isMobile.value) {
                const parsedModelValueDate = Date.parse(props.modelValue);
                if (
                    isNaN(parsedModelValueDate) ||
                    (props.minValidDate && formatDate(parsedModelValueDate, 'yyyy-MM-dd') < props.minValidDate)
                ) {
                    internalModelValue.value = '';
                } else {
                    internalModelValue.value = props.modelValue;
                }
            } else {
                internalModelValue.value = props.modelValue
                    ? new Date(`${props.modelValue}T00:00:00`)
                    : props.modelValue;
            }
        },
    );
    watch(internalModelValue, () => {
        let eventValue;
        if (isMobile.value) {
            eventValue = internalModelValue.value;
        } else {
            eventValue = internalModelValue.value ? formatDate(internalModelValue.value, 'yyyy-MM-dd') : null;
        }
        emit('update:modelValue', eventValue);
    });
</script>

<template>
    <div v-if="isMobile" class="mobile_input_wrapper px-4">
        <input
            v-model="internalModelValue"
            type="date"
            class="el-input__inner"
            :min="minValidDate"
            :max="maxValidDate" />
    </div>
    <ElDatePicker
        v-else
        v-model="internalModelValue"
        type="date"
        :format="format"
        class="jet-date-picker"
        :popper-class="enableYearArrowNavigation ? '' : 'jet-date-picker--disable-arrow-navigation'"
        :class="{'jet-date-picker--full-width': fullWidth}" />
</template>

<!-- This style section is intentionally not scoped due to limitations in the customization of Element components -->
<style lang="scss">
    .jet-date-picker input {
        font-family: inherit;
        font-weight: var(--jet-font-weight-regular-medium);
    }

    // Because datepicker inputs had
    // a smaller height than other inputs
    .el-date-editor.el-input {
        height: auto;
    }

    .jet-date-picker--full-width.el-date-editor.el-input {
        width: 100%;
    }

    // Default borders override (box-shadow -> proper border)
    .jet-date-picker .el-input__wrapper,
    .jet-date-picker .el-input__wrapper:hover,
    .jet-date-picker .el-input__wrapper:active,
    .jet-date-picker .el-input.is-focus .el-input__wrapper,
    .jet-date-picker .el-input.is-focus .el-input__wrapper:hover,
    .jet-date-picker .el-input.is-focus .el-input__wrapper:active,
    .jet-date-picker .el-input.is-disabled .el-input__wrapper,
    .jet-date-picker .el-input.is-disabled .el-input__wrapper:hover,
    .jet-date-picker .el-input.is-disabled .el-input__wrapper:active {
        box-shadow: none !important;
        border-style: var(--jet-input-border-style);
        border-width: var(--jet-input-border-width);
    }

    .jet-date-picker .el-input__wrapper,
    .jet-date-picker .el-input__wrapper:hover {
        border-color: var(--el-border-color-darker);
    }

    .jet-date-picker .el-input__wrapper.is-focus {
        border-color: var(--el-color-primary);
    }

    .jet-date-picker.is-disabled .el-input__wrapper,
    .jet-date-picker.is-disabled .el-input__wrapper:hover,
    .jet-date-picker.is-disabled .el-input__wrapper:active {
        border-color: var(--el-border-color-lighter);
        resize: none;
    }

    .jet-date-picker--disable-arrow-navigation {
        .d-arrow-right,
        .d-arrow-left {
            display: none;
        }
    }

    .jet-date-cell {
        &--warning {
            .el-date-table-cell {
                background: var(--jet-color-warning-light-8);
            }
        }
    }

    .mobile_input_wrapper {
        display: inline-flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        background-color: var(--el-input-bg-color, var(--el-color-white));
        background-image: none;
        border-radius: var(--el-input-border-radius, var(--jet-input-border-radius));
        transition: var(--el-transition-box-shadow);
        transform: translate3d(0, 0, 0);
        box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color-darker)) inset;

        & input {
            height: calc(var(--el-input-height, 40px) - 2px);
        }
    }
</style>
