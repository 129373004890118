import HiringDocumentCommonApi from '@jetCommon/api/hiring-document.js';

export default class HiringDocumentApi extends HiringDocumentCommonApi {
    buildFromTemplate(hiringDocument) {
        return this.apiPost('build_from_template/', hiringDocument);
    }

    buildFromFile(hiringDocument) {
        return this.apiPost('build_from_file/', hiringDocument);
    }

    updateTemplateDocument(id, hiringDocument) {
        return this.apiPatch(`${id}/update_template_document/`, hiringDocument);
    }
}
