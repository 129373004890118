import {formatNumberToIta} from '@jetCommon/helpers/number.js';

function formatAmount(val, toFixed) {
    const amount = formatNumberToIta(val, toFixed);
    return `${amount} €`;
}

/**
 * Check if a Number is an integer.
 * Trick: an integer modulo 1 is always 0.
 * Alternative: Number(stringValue) === Number.parseInt(stringValue,10)
 * @param value {Number}
 * @returns {boolean}
 */
const isInt = value => value % 1 === 0;

/**
 * Format an amount with 2 decimals if it's a float, without decimals if the actual number is an integer.
 * Note: null and undefined are treated as 0.
 *
 * @param value {Number|string|null|undefined}
 * @returns {string}
 */
function formatAmountWithOptionalDecimals(value) {
    const numberValue = Number(value || 0);
    const toFixed = isInt(numberValue) ? 0 : 2;
    return formatAmount(numberValue, toFixed);
}

function formatAmountArray(values, toFixed = 2, separator = ',', lastSeparator = 'o') {
    const _values = values.map(v => formatAmount(v, toFixed));
    const _separator = `${separator} `;
    const _lastSeparator = ` ${lastSeparator} `;
    return [..._values.slice(0, -2), _values.slice(-2).join(_lastSeparator)].join(_separator);
}

export {formatAmount, formatAmountWithOptionalDecimals, formatAmountArray};
