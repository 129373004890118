import {ApiBase} from '@jetCommon/api/base.js';

export default class LocationApiCommon extends ApiBase {
    static resourceName = 'locations';

    getCountries(params) {
        return this.apiGet('countries/', {params});
    }

    getProvinces(params) {
        return this.apiGet('provinces/', {params});
    }

    getMunicipalities(params) {
        return this.apiGet('municipalities/', {params});
    }
}
