import {START_LOCATION} from 'vue-router';
import {commonRoutes} from '@jetCommon/router.js';
import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';
import settings from '@/env.js';

const routes = [
    {
        path: `/:${settings.JET_ROUTE_ENTITY_NAME}/onboarding`,
        component: () => import('@/layouts/SidebarLayout.vue'),
        children: [
            {
                path: '',
                component: () => import('@/features/onboarding/views/OnboardingView.vue'),
                name: 'onboardingView',
            },
        ],
    },
];

// Routes that are visible during onboarding
const onboardingRouteNames = [
    'collaboratorHiringView',
    'employeeHiringView',
    'firstAccessView',
    'hiringCoCoCoView',
    'hiringCostSimulatorView',
    'hiringEmployeeView',
    'hiringInternView',
    'hiringsView',
    'hiringsViewWithTab',
    'hiringView',
    'newHiringView',
    'onboardingView',
    'sepaMandateView',
    'settingsCompanyAdminRegistrationView',
    'settingsView',
    ...commonRoutes.filter(route => route.name).map(route => route.name),
];

function beforeEachHandler(to, from) {
    const {accountIsFullyActivated, companyId, onboardingIsActive} = useOnboarding();

    if (onboardingIsActive.value) {
        if (
            (to.name === 'dashboardView' && from === START_LOCATION) ||
            (!accountIsFullyActivated.value && !onboardingRouteNames.includes(to.name))
        ) {
            return {name: 'onboardingView', params: {companyId: companyId.value}};
        }
    }
}

export {beforeEachHandler, routes};
