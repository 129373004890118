<script setup>
    import {ONBOARDING_TASK_CODENAME} from '@jetCommon/constants/onboarding.js';
    import {addMonths, getYear, getMonth, parseISO} from 'date-fns';
    import {capMonthName} from '@jetCommon/helpers/date.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import ListItem from '@jetCommon/components/ListItem.vue';
    import OnboardingTaskFileItem from '@/features/onboarding/components/tasks/base/OnboardingTaskFileItem.vue';

    const {remoteData} = useOnboarding();

    const firstPayrollDate = computed(() => parseISO(remoteData.value.company_first_payroll_date));
    const firstPayrollYear = computed(() => getYear(firstPayrollDate.value));
    const fromMonthDescription = computed(() => `Dicembre ${firstPayrollYear.value - 1}`);
    const toMonth = computed(() => getMonth(addMonths(firstPayrollDate.value, -2)));
    // capMonthName requires a [1,12] index which will be converted to a [0,11] index
    const toMonthDescription = computed(() => `${capMonthName(toMonth.value + 1)} ${firstPayrollYear.value}`);
    const description = computed(() => {
        const partial =
            {
                0: `al ciclo paghe di ${fromMonthDescription.value}`, // Onboarding starts on January
                1: `al ciclo paghe di ${fromMonthDescription.value}`, // Onboarding starts on February
            }[getMonth(firstPayrollDate.value)] ||
            `ai cicli paghe da ${fromMonthDescription.value} a ${toMonthDescription.value}`; // Otherwise
        return `Carica i file relativi ${partial} in Jet HR`;
    });
</script>

<template>
    <OnboardingTaskFileItem
        :codename="ONBOARDING_TASK_CODENAME.LUL_F24_PROSPECTS"
        confirm-button-text="Carica file"
        :description="description"
        has-template
        modal-title="LUL, F24, Prospetti contabili mesi passati"
        template-confirm-button-text="Scarica lo zip"
        template-action-button-text="Scarica lo zip"
        title="LUL, F24, Prospetti contabili mesi passati">
        <template #dialogTemplateBody>
            <ListItem icon-name="files" show-separator>
                Carica i documenti di Dicembre dell'anno scorso e per ogni mese dell'anno corrente.
            </ListItem>
            <ListItem icon-name="folders" show-separator>
                Inserisci i file rispettando la struttura delle cartelle all'interno dello zip
            </ListItem>
            <ListItem icon-name="file-zip" show-separator>
                Quando hai recuperato tutti i file ricarica lo zip completo
            </ListItem>
        </template>
    </OnboardingTaskFileItem>
</template>
