<script setup>
    // TODO: move this composable in JetCommon and parametrize breakpoints
    import 'element-plus/es/components/dialog/style/index';
    import {ElDialog} from 'element-plus';
    import {useDesignSystemTools} from '@jetCommon/composables/design-system-tools.js';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetScrollbar from '@jetDS/components/JetScrollbar.vue';

    const props = defineProps({
        show: {type: Boolean, required: true},
        title: {type: String, default: ''},
        subtitle: {type: String, default: null},
        width: {type: String, default: null},
        hideHeader: {type: Boolean, default: false},
        hideHeaderCloseIcon: {type: Boolean, default: false},
        hideFooter: {type: Boolean, default: false},
        preventFullscreen: {type: Boolean, default: false},
        noMargin: {type: Boolean, default: false},
        fullHeight: {type: Boolean, default: false},
    });

    const emit = defineEmits(['update:show']);

    const {showDialogsFullScreen} = useDesignSystemTools();

    const slots = useSlots();

    const showDialog = ref(props.show);
    watch(
        () => props.show,
        () => {
            showDialog.value = props.show;
        },
    );
    watch(showDialog, () => {
        emit('update:show', showDialog.value);
    });

    function closeDialog() {
        showDialog.value = false;
    }
    defineExpose({closeDialog});
</script>

<template>
    <ElDialog
        v-model="showDialog"
        class="jet-dialog"
        :class="{
            'jet-dialog--custom-width': width,
            'jet-dialog--no-header': hideHeader,
            'jet-dialog--no-footer': hideFooter,
            'jet-dialog--mobile-max-width': hideFooter,
            'jet-dialog--no-margin': noMargin,
        }"
        :width="width ? width : null"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :fullscreen="showDialogsFullScreen && !preventFullscreen"
        :title="title">
        <template #header="{close, titleId, titleClass}">
            <div class="jet-dialog__title-wrapper">
                <div class="jet-dialog__title-container">
                    <h2 :id="titleId" class="semibold lg jet-dialog__title" :class="titleClass">
                        {{ title }}
                        <slot name="titleExtra" />
                    </h2>
                    <h4 v-if="subtitle" class="light base jet-dialog__subtitle mt-1">
                        {{ subtitle }}
                    </h4>
                </div>
                <JetButton
                    v-if="!hideHeaderCloseIcon"
                    text
                    icon-style="regular"
                    icon-name="close"
                    class="jet-dialog__btn-close"
                    @click="close" />
            </div>
        </template>

        <div class="jet-dialog__body" :class="{'jet-dialog__full_height': fullHeight}">
            <JetScrollbar max-height="90vh" :view-class="fullHeight ? 'jet-dialog__view' : ''">
                <slot name="body" />
            </JetScrollbar>
        </div>

        <template v-if="slots.footer" #footer>
            <slot name="footer" />
        </template>
    </ElDialog>
</template>

<style scoped lang="scss">
    .jet-dialog__title-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .jet-dialog__title {
        display: flex;
        flex-direction: row;
        color: var(--el-text-color-primary);
    }

    .jet-dialog__subtitle {
        color: var(--el-text-color-regular);
    }

    .jet-dialog__btn-close {
        :deep(.el-icon svg) {
            width: var(--jet-dialog-close-icon-size);
            height: var(--jet-dialog-close-icon-size);
        }
    }
</style>

<style lang="scss">
    @use '@jetDS/scss/_media-queries.scss' as *;

    @include media-query('lg-and-up') {
        .jet-dialog--custom-width.el-dialog {
            --el-dialog-width: v-bind(width);
        }
    }

    .jet-dialog__full_height {
        height: 100%;
    }

    .jet-dialog__view {
        height: 100%;
    }

    .jet-dialog--custom-width .jet-dialog__body {
        max-width: v-bind(width);

        @include media-query('sm-and-up') {
            margin: var(--jet-sp-6) var(--jet-sp-4) var(--jet-sp-4);
        }
    }

    .jet-dialog.jet-dialog--no-header {
        .el-dialog__header {
            display: none;
        }
    }

    .jet-dialog--no-footer {
        footer {
            display: none;
        }
    }

    .jet-dialog.jet-dialog--mobile-max-width {
        max-width: 90vw;
    }

    .jet-dialog--no-margin .jet-dialog__body {
        margin: var(--jet-sp-0);
    }
</style>
