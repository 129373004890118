<script setup>
    import 'element-plus/es/components/form/style/index';
    import {ElForm} from 'element-plus';

    const elFormRef = ref();

    defineExpose({
        getElFormRef: () => elFormRef.value,
    });
</script>

<template>
    <ElForm ref="elFormRef" class="jet-form" label-position="top" hide-required-asterisk>
        <slot />
    </ElForm>
</template>
