const EXPENSE_KIND = {
    FOOD: 'FOOD',
    TRAVELS: 'TRAVELS',
    KM: 'KM',
    TELECOM: 'TELECOM',
    HOTELS: 'HOTELS',
    DEVICES: 'DEVICES',
    SERVICES: 'SERVICES',
    OTHER: 'OTHER',
    CUSTOM: 'CUSTOM',
};

const EXPENSE_KIND_ICONS = {
    [EXPENSE_KIND.FOOD]: {
        iconName: 'utensils',
        iconColor: 'blue',
    },
    [EXPENSE_KIND.TRAVELS]: {
        iconName: 'car',
        iconColor: 'purple',
    },
    [EXPENSE_KIND.KM]: {
        iconName: 'car',
        iconColor: 'purple',
    },
    [EXPENSE_KIND.TELECOM]: {
        iconName: 'phone',
        iconColor: 'lime',
    },
    [EXPENSE_KIND.HOTELS]: {
        iconName: 'hotel',
        iconColor: 'brown',
    },
    [EXPENSE_KIND.DEVICES]: {
        iconName: 'battery',
        iconColor: 'violet',
    },
    [EXPENSE_KIND.SERVICES]: {
        iconName: 'suitcase',
        iconColor: 'green',
    },
    [EXPENSE_KIND.OTHER]: {
        iconName: 'circle-ellipsis',
        iconColor: 'primary',
    },
    [EXPENSE_KIND.CUSTOM]: {
        iconName: 'star',
        iconColor: 'lime',
    },
};

const EXPENSE_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
};

const EXPENSE_PAYSLIP_EXPORT_TYPE = {
    SINGLE: 'SINGLE',
    CUMULATIVE: 'CUMULATIVE',
};

export {EXPENSE_KIND, EXPENSE_KIND_ICONS, EXPENSE_STATUS, EXPENSE_PAYSLIP_EXPORT_TYPE};
